import { BASE_URL } from "src/constants/vars";
import { setCredentials, logOut } from "../reducers/auth";
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { clearStorage } from "src/utils/auth";
import i18n from "src/setup/i18n";


const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }: { getState: any }) => {
        const accessToken = getState().auth.access;
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }
        headers.set('Accept-Language', i18n.language);
        return headers
    }
})

const logOutAction = (api: any) => {
    api.dispatch(logOut());
    clearStorage();
}

const baseQueryWithReauth: typeof baseQuery = async (args, api: any, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        const refreshToken = localStorage.getItem("mk_store_token");

        if (refreshToken) {
            try {

                // Need to check
                const response = await fetch(`${BASE_URL}/auth/refresh/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        refresh: refreshToken,
                    }),
                });
                const { access } = await response.json();
                api.dispatch(setCredentials({ ...api.getState().auth, access }));
                result = await baseQuery(args, api, extraOptions);
            }
            catch (error) {
                logOutAction(api);
            }
        }
        else {
            logOutAction(api);
        }

    }
    return result;
}


const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({

    })
})

export default apiSlice;

