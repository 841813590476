import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import {
    HiViewGrid, HiMenu, HiOutlineSearch,
    HiOutlineShoppingCart, HiOutlineUserCircle,
    HiOutlineLogout,
    HiOutlineHome, HiOutlineShoppingBag,
    HiOutlineHeart, HiOutlineQuestionMarkCircle
} from "react-icons/hi";


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom';


import DarkLogo from "src/assets/images/dark_logo.png";
import { getTokenData, capitalize, isRTL } from 'src/utils/global';
import { clearAllStorage } from 'src/utils/auth';

import { authSelector, logOut } from 'src/store/reducers/auth';
import classes from './styles.module.css';
import logo from 'src/assets/images/logo.svg';
import CatsMenu from '../cats/CatsMenu';
import { cartCountSelector, cartLoadingSelector, cartReset } from 'src/store/reducers/cart';

const ICON_SIZE = 22


export default () => {
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();

    const [openMenu, setOpenMenu] = useState(false);
    const auth = useSelector(authSelector);
    const userData = getTokenData(auth.access);
    const cartLoading = useSelector(cartLoadingSelector);
    const cartCount = useSelector(cartCountSelector)

    // =============================
    // Handlers ====================
    // =============================
    const openMenuHandle = () => setOpenMenu(true);

    const logoutHandle = () => {
        dispatch(cartReset());
        clearAllStorage();
        dispatch(logOut());
    };

    const wishlistPath = auth.access ? "/wishlist" : "/auth/login/?next=/wishlist"


    return (
        <header>
            <Navbar key={'xl'} fixed="top" expand={'xl'} className={`${classes.nav} mb-3`}>
                <Container fluid>
                    <div className={`${classes.cat__toggle}`} onClick={openMenuHandle}>
                        <HiViewGrid size={28} />
                    </div>
                    <Navbar.Brand href="/">
                        <img src={logo} alt='Malek Store' height={43} width={96} />
                    </Navbar.Brand>
                    <Navbar.Toggle className={classes.toggle} aria-controls={`offcanvasNavbar-expand-xl`} >
                        <HiMenu size={ICON_SIZE + 4} />
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton className={classes.offcanvas__header}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                <img src={DarkLogo} alt={t("store_name")} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className={classes.offcanvas__body}>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <NavLink className={`${classes.item__link} nav-link ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")}`} to="/">
                                    <HiOutlineHome />
                                    {t("nav.home")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} nav-link ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")}`} to="/products">
                                    <HiOutlineShoppingBag />
                                    {t("nav.products")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")} nav-link`} to={wishlistPath}>
                                    <HiOutlineHeart />
                                    {t("nav.wishlist")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")} nav-link`} to="about-us/">
                                    <HiOutlineQuestionMarkCircle />
                                    {t("nav.about_us")}
                                </NavLink>
                                <div className={classes.icons__con}>
                                    <Link to={'/products?search=true'} className={`search ${classes.item__link}`} style={{ marginLeft: isRTL() ? 8 : 0 }}>
                                        <HiOutlineSearch size={ICON_SIZE} />
                                    </Link>
                                    <Link to={'/cart'} className={`cart ${classes.item__link}`}>
                                        <HiOutlineShoppingCart size={ICON_SIZE} />
                                        <div className={classes.cart__count}>
                                            {cartLoading ? <Spinner size="sm" className={classes.cart__spinner} /> : cartCount}
                                        </div>
                                    </Link>

                                    {auth.access ?
                                        <NavDropdown
                                            className={classes.dropdown__menu}
                                            align="end"
                                            title={
                                                <div className={classes.item__link}>
                                                    <HiOutlineUserCircle size={23} />
                                                </div>
                                            }
                                            id="basic-nav-dropdown"
                                            onSelect={() => document.body.click()} // Closes the dropdown
                                        >
                                            <div className={classes.userData}>
                                                {userData ? (
                                                    <>
                                                        <div className={classes.username}>
                                                            {capitalize(userData.username || '')}
                                                        </div>
                                                        <div className={classes.email}>
                                                            {userData.email || ''}
                                                        </div>
                                                    </>
                                                ) : ""}
                                            </div>
                                            <NavDropdown.Divider />
                                            <Link
                                                className={`dropdown-item ${classes.dropdown__item}`}
                                                to={"/profile"}
                                                onClick={() => document.body.click()} // Close on click
                                            >
                                                <HiOutlineUserCircle size={21} /> {t('common:nav.profile')}
                                            </Link>
                                            <NavDropdown.Item
                                                className={classes.dropdown__item}
                                                onClick={() => {
                                                    logoutHandle();
                                                    document.body.click(); // Close after logout
                                                }}
                                            >
                                                <HiOutlineLogout size={21} /> {t('common:logout')}
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        :
                                        <NavLink to={"/auth/login/"} className={`btn__primary ${classes.nav__btn}`} id="variant_2" >
                                            {t("nav.signIn")}
                                        </NavLink>
                                    }
                                </div>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
                <CatsMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
            </Navbar>
            <div className={classes.prop}>
            </div>
        </header >
    );
}
