import Slider from "react-slick";
import { ProductType } from "src/pages/products/product/types"

import './styles.css';

import classes from './styles.module.css'
import Product from "src/pages/products/product/Product";
import DefaultProduct from "src/pages/products/product/DefaultProduct";



export interface ProductsProp {
    data: ProductType[],
    extraClasses?: string,
    settings?: any
}


const ProductsSlider = ({ data, extraClasses, settings }: ProductsProp) => {
    const conf = settings || SETTINGS;
    const extraLength = data.length > 3 ? 0 : 4 - data.length;

    return (
        <div className={`${classes.products} ${extraClasses || ''}`}>
            <Slider {...conf}>
                {data.map(product => (
                    <div key={`product__${product.id}`} className="p-2 py-3">
                        <Product product={product} />
                    </div>
                ))}
                {
                    Array.from({ length: extraLength }, (item, index) => (
                        <div key={`default__product__${index}`} className="p-2 py-3">
                            <DefaultProduct />
                        </div>
                    ))
                }
            </Slider>
        </div >
    );
}

export default ProductsSlider;


const SETTINGS = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2500, // Slightly faster autoplay
    speed: 700,
    adaptiveHeight: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
        {
            breakpoint: 991, // Medium devices (tablets)
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,

            }
        },
        {
            breakpoint: 768, // Small devices (large phones)
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480, // Extra small devices (phones)
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
};
